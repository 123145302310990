
import React from "react";

// reactstrap components
import {  Container, Row, Col } from "reactstrap";
import { ConfigContext } from "../../Context"
import Auth from '../../helpers/Auth';

const CredHeader = ({ heading }) => {

  const { config } = React.useContext(ConfigContext);
  const branding = Auth.getBranding();

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "0px",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className={`mask opacity-8`} style={{ background: branding.primaryColor ?? config.connectionBgColor }} />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col >
              <h1 className="display-2 text-white">{heading}</h1>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CredHeader;
