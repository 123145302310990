import axios from 'axios'
import Auth from '../helpers/Auth';

class InsightServicesClass {

    constructor() {
        this.headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('itoken')
        };
    }

    getLocationsAndCategories = () => {

        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + '/api/locations-and-categories'

                axios.get(API_PATH,
                    {
                        headers: this.headers,
                    }
                ).then((data) => {
                    if (data.data.hasTokenExpired) {
                        Auth.signout();
                        window.location.reload();
                    }
                    else if (data.data) {
                        resolve(data.data)
                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve([])
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }

    getPrompts = (filters) => {
        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + '/api/prompts'

                axios.get(API_PATH,
                    {
                        headers: this.headers,
                        params: filters
                    }
                ).then((data) => {
                    if (data.data.hasTokenExpired) {
                        Auth.signout();
                        window.location.reload();
                    }
                    else if (data.data) {
                        resolve(data.data)
                    } else {
                        resolve([]);
                    }
                }).catch((e) => {
                    console.log({ e })
                    resolve([])
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }
}

const InsightServices = new InsightServicesClass;

export default InsightServices;
