import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Spinner } from "reactstrap"
import { ConfigContext } from "../../Context";
import Auth from '../../helpers/Auth.js';

const ConfirmationModal = ({ headerText, showModal, hideModal, confirmModal, id, message, alertClass, proceedBtnText, loading }) => {
    const { config } = React.useContext(ConfigContext);
    const branding = Auth.getBranding();

    return (
        <Modal isOpen={showModal} onHide={hideModal} centered>
            <ModalHeader closeButton>
                <h1>{headerText ? headerText : 'Delete Confirmation'}</h1>
            </ModalHeader>
            <ModalBody className='py-0'><div className={`alert ${alertClass}`}>{message}</div></ModalBody>
            <ModalFooter>
                <Button color="default" onClick={hideModal}>
                    Cancel
                </Button>
                <Button disabled={loading} color="danger" onClick={() => confirmModal(id)}>
                    {proceedBtnText ? proceedBtnText : "Delete"} {loading && <Spinner animation="grow" size={'sm'} />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal;