
/*eslint-disable*/
import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link, useHistory } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { shared_info } from "../../content";
import jwt from "jsonwebtoken";
var ps;
import Auth from '../../helpers/Auth';
import { ConfigContext } from "../../Context"
import PermissionsGate from "components/Permissions/PermissionsGate";

const Sidebar = (props) => {

  const { config } = React.useContext(ConfigContext);
  const branding = Auth.getBranding();

  const history = useHistory();

  const [isValidateLoading, setIsValidateLoading] = useState(false);
  const [isValidateModalOpen, setIsValidateModalOpen] = useState(false);
  const [validateVerificationData, setValidateVerificationData] = useState({})
  const toggleValidateModal = () => {
    setIsValidateLoading(true);
    setIsValidateModalOpen(true);
    setIsValidateModalOpen(!isValidateModalOpen);
  }

  const [collapseOpen, setCollapseOpen] = useState();

  const logout = () => {
    Auth.signout();
    window.location.reload();
  }

  const settings = () => {
    history.push('/admin/change-password');
  }

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    const iToken = localStorage.getItem("itoken")
    let decodedToken;
    try {
      decodedToken = jwt.decode(iToken)
    } catch (e) {
      console.log({ e })
    }
    return routes.map((prop, key) => {
      if (prop.layout === '/admin' && prop.includeInSidebar) {
        // if (prop.roles.includes(decodedToken["role"])) {
        return (
          <PermissionsGate hasPermission={prop.permission}>
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            </NavItem>
          </PermissionsGate>
        );
        // }
      }
    })
  }

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  // if (logo && logo.innerLink) {
  //   navbarBrandProps = {
  //     to: logo.innerLink,
  //     tag: Link,
  //   };
  // } else if (logo && logo.outterLink) {
  navbarBrandProps = {
    href: '/admin',
    // target: "_blank",
  };
  // }

  return (

    //Contact Us Modal Here Start
    <>
      <Modal
        toggle={toggleValidateModal}
        isOpen={isValidateModalOpen}
        backdrop="static"
      >
        <ModalHeader toggle={toggleValidateModal}>
          <h2>{shared_info.modal_contactus_heading}</h2>
        </ModalHeader>

        <ModalBody className="py-1">
          <div>
            <h3>
              {shared_info.modal_contactus_discord_label} <a href={shared_info.modal_contactus_discord_value} target="_blank">{shared_info.modal_contactus_discord_value} </a></h3>
            <h3>
              {shared_info.modal_contactus_email_label} <a href={`mailto: ${shared_info.modal_contactus_email_value}`} target="_blank"> {shared_info.modal_contactus_email_value}</a> </h3>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button style={{ backgroundColor: branding.buttonColor ?? "#11cdef", color: branding.buttonTextColor ?? "#000" }} onClick={() => setIsValidateModalOpen(false)}>
            {shared_info.button_close}
          </Button>
        </ModalFooter>
      </Modal>


      {/* // here Contact Us Modal End */}
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                // alt={logo.imgAlt}
                className="navbar-brand-img"
                // src={require("../../assets/img/brand/logo.png").default}
                src={logo}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/theme/team-1-800x800.jpg")
                          .default
                      }
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h5 className="text-overflow m-0">{shared_info.welcome_text}</h5>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={settings}>
                  <i className="ni ni-settings" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem onClick={logout}>
                  <i className="ni ni-button-power" />
                  <span>{shared_info.logout_text}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt}
                          src={require("../../assets/img/brand/logo-black.png").default}
                        />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt}
                          src={require("../../assets/img/brand/logo-black.png").default}
                        />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            {/* Navigation */}
            <Nav navbar>{createLinks(routes)}</Nav>
            {/* Divider */}
            <hr className="my-3" />
            {/* Heading */}
            <h6 className="navbar-heading text-muted">Help</h6>
            {/* Navigation */}
            <Nav className="mb-md-3" navbar>
              {config.sideBarLinks?.map((data) => {
                return (
                  <NavItem onClick={toggleValidateModal}>
                    <NavLink href={data.link}>
                      <i className="ni ni-send" />
                      {data.title}
                    </NavLink>
                  </NavItem>
                )
              })}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
