const PERMISSIONS = {
    view_insights: 'can_view_insights',

    view_users: 'can_view_users',
    add_user: 'can_add_user',
    edit_user: 'can_edit_user',
    delete_user: 'can_delete_user',
    
    change_password: 'can_change_password',
}

export default PERMISSIONS;