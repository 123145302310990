
/*eslint-disable*/
import React, { useContext } from "react";
import { ConfigContext } from "../../Context"

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

const { version } = require('../../../package.json');
import Auth from '../../helpers/Auth';

const Footer = () => {

  const { config } = React.useContext(ConfigContext);
  const branding = Auth.getBranding();

  return (
    <footer className="footer">
      {/* <Row className="align-items-center justify-content-center">
        <div style={{ color: branding.primaryTextColor ?? "#11CDEF" }} className="copyright text-center text-xl-left">
          © {new Date().getFullYear()} | {version && version + " |"}
          <a
            className="font-weight-bold ml-1"
            href={config.footer?.link}
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: branding.primaryTextColor ?? "#11CDEF" }}
          >
            {config.footer?.text}
          </a>
        </div>
      </Row> */}
      <Container>
        <Row className="align-items-center text-center justify-content-center">
          <div className="copyright text-center text-xl-left text-muted">
            <span className="mr-2">Powered by</span>
            <img
              style={{ height: 25 }}
              alt={config.authNav?.imgAlt}
              src={require("../../assets/img/brand/logo-black.png").default}
            />
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
