import React, { useState, useEffect, useCallback } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Spinner,
} from "reactstrap";

import Plot from 'react-plotly.js';

import { FaRegCirclePlay } from "react-icons/fa6";
import { EventSourcePolyfill } from 'event-source-polyfill';
import { generatePrompt } from 'helpers/utils';

function Prompt({ branding, config, prompt, location, executeAll, setExecuteAll }) {

    const [text, setText] = useState('');
    const [graphData, setGraphData] = useState(null); // [ {x: [1,2,3], y: [1,2,3]}]
    const [streamStarted, setStreamStarted] = useState(false);

    const [loading, setLoading] = useState(false);

    const [eventSource, setEventSource] = useState(null); // Store the EventSource instance

    const startStream = useCallback(async () => {
        setLoading(true);
        setText('')
        setGraphData(null);

        const promptText = generatePrompt(prompt.promptText, branding.organizationName, location);

        const source = new EventSourcePolyfill(process.env.REACT_APP_API_URL + `/api/execute-prompt?prompt=${promptText}&graphType=${prompt.graphType}&graphPrompt=${prompt.graphPrompt}`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('itoken')
            }
        });

        source.onmessage = event => {
            // Update the text state with the received chunk of text
            const dataType = JSON.parse(event.data).type;

            if (dataType === 'graph_data') {
                setGraphData(JSON.parse(event.data).data);
            }
            else if (dataType === 'text') {
                setText(prevText => prevText + JSON.parse(event.data)?.text ?? '');
            }

            // const text = JSON.parse(event.data).text;
            // setText(prevText => prevText + text);
        };

        source.onerror = async error => {
            console.error('SSE Error:', error);
            source.close();

            setLoading(false);
        };

        // Set streamStarted state to true and store the EventSource instance
        setStreamStarted(true);
        setLoading(false);
        setEventSource(source);

    }, [prompt, location, branding.organizationName]);

    // Cleanup function to close the SSE connection when the component unmounts
    useEffect(() => {
        return () => {
            if (eventSource) {
                eventSource.close();
            }
        };
    }, [eventSource])

    useEffect(() => {
        if (executeAll) {
            startStream();
            setExecuteAll(false)
        }
    }, [executeAll, setExecuteAll, startStream])

    return (
        <Container>
            <Row>
                <Col className="order-xl-1 mt-5">
                    <Card style={{ border: "1px solid black" }} className="bg-secondary shadow">
                        <CardHeader style={{ background: branding.primaryColor ?? config.connectionBgColor, color: "#fff" }} className="border-0">
                            <Row className="align-items-center">
                                <Col >
                                    <h3 style={{ color: "#fff" }} className="mb-0 d-flex align-items-center">{prompt?.title}
                                        {/* {loading ? <Spinner className="ml-3" size="sm" animation="grow" /> : <FaRegCirclePlay size="25px" className="refresh ml-3" onClick={startStream} />} */}
                                    </h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="prompt-card-body">
                            <pre className='prompt-text'>{!streamStarted ? prompt?.placeholder : text}</pre>
                            {graphData && (prompt.graphType === 'bar_chart' ? <Plot
                                data={[
                                    { type: 'bar', marker: { color: branding.primaryColor }, x: graphData?.x, y: graphData?.y },
                                ]}
                                useResizeHandler={true}
                                layout={{ autosize: true, title: graphData?.title }}
                                style={{ width: "100%", height: "100%" }}
                                config={{ displayModeBar: false, responsive: true }}
                            /> : <Plot
                                data={[{
                                    values: graphData?.values,
                                    labels: graphData?.labels,
                                    type: 'pie'
                                }]}
                                useResizeHandler={true}
                                layout={{ autosize: true, title: graphData?.title }}
                                style={{ width: "100%", height: "100%" }}
                                config={{ displayModeBar: false, responsive: true }}
                            />)}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
}

export default Prompt;
