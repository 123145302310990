import Header from "../components/Headers/ConnectionHeader.js";
import React, { useState, useEffect, useCallback } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
    FormGroup,
    InputGroup,
    Input,
    Collapse,
} from "reactstrap";
import { insights_screen } from "../content.js";
import InsightServices from 'services/Insights.js';

import { ConfigContext } from "../Context";
import Auth from '../helpers/Auth.js';
import Prompt from "../components/Prompts/Prompt.js";

const Insights = () => {

    const { config } = React.useContext(ConfigContext);
    const branding = Auth.getBranding();

    const [filter, setFilter] = useState({ location: 'global', category: 'all' });

    const [locations, setLocations] = useState([]);
    const [categories, setCategories] = useState([]);
    const [executeAll, setExecuteAll] = useState(false);

    const [prompts, setPrompts] = useState([]);

    const getPrompts = useCallback(async () => {
        try {
            const resp = await InsightServices.getPrompts(filter);

            setPrompts(resp.prompts);
        } catch (err) {
            console.error({ err })
        }
    }, [filter])

    useEffect(() => {
        getLocationsAndCategories();
        getPrompts();


    }, [getPrompts]);

    const getLocationsAndCategories = async () => {
        try {
            // setGetReqLoading(true);

            const resp = await InsightServices.getLocationsAndCategories();

            setLocations(resp.locations);
            setCategories(resp.categories);

            // setGetReqLoading(false);
        } catch (err) {
            console.error({ err })
        }
    }

    const applyFilter = () => {
        try {
            getPrompts().then(() => setExecuteAll(true));
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <Header headerPadding='pb-4 pt-5 pt-md-5' />
            <Container>
                <Card style={{ border: "1px solid black" }} className="bg-secondary shadow mt-4">
                    <CardHeader style={{ background: branding.primaryColor ?? config.connectionBgColor, color: "#fff" }} data-type="collapseBanner">
                        <h3 style={{ color: "#fff" }} className="mb-0 d-flex align-items-center cursor-pointer">{insights_screen.sub_heading}</h3>
                    </CardHeader>
                    <Collapse isOpen={true}>
                        <CardBody>
                            <Row>
                                <Col className="order-xl-1">
                                    <Row>
                                        <div className="col-md-5 mt-2">
                                            <FormGroup>
                                                <label className="form-control-label required" htmlFor="input-email">
                                                    Location
                                                </label>

                                                <InputGroup className="input-group-alternative" >
                                                    <Input
                                                        placeholder="Select Location"
                                                        type="select"
                                                        style={{ width: 'auto' }}
                                                        onChange={(e) => setFilter(filter => ({ ...filter, location: e.target.value }))}
                                                        value={filter.location ? filter.location : ''}
                                                    >
                                                        <option key="placeholder" value="" disabled selected>Select Location</option>
                                                        <option key="all" value="global">Global</option>
                                                        {locations?.map((location, index) => (
                                                            <option key={index} value={location}>{location}</option>
                                                        ))}
                                                    </Input>
                                                </InputGroup>
                                            </FormGroup>

                                        </div>
                                        <div className="col-md-5 mt-2">
                                            <FormGroup>
                                                <label className="form-control-label required" htmlFor="input-email">
                                                    Category
                                                </label>

                                                <InputGroup className="input-group-alternative" >
                                                    <Input
                                                        placeholder="Select Category"
                                                        type="select"
                                                        style={{ width: 'auto' }}
                                                        onChange={(e) => setFilter(filter => ({ ...filter, category: e.target.value }))}
                                                        value={filter.category ? filter.category : ''}
                                                    >
                                                        <option key="placeholder" value="" disabled selected>Select Category</option>
                                                        <option key="all" value="all">All</option>
                                                        {categories?.map((category, index) => (
                                                            <option key={index} value={category._id}>{category.name}</option>
                                                        ))}
                                                    </Input>
                                                </InputGroup>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-2 mt-3 d-flex align-items-center">
                                            <Button style={{ backgroundColor: branding.buttonColor ?? "#11cdef", color: branding.buttonTextColor ?? "#000" }} size="l" onClick={() => applyFilter()}>
                                                {insights_screen.button_search}
                                            </Button>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Collapse>
                </Card>
            </Container>
            {/* Prompts */}
            {prompts?.map((prompt) => <Prompt key={prompt._id} branding={branding} config={config} location={filter.location} prompt={prompt} executeAll={executeAll} setExecuteAll={setExecuteAll} />)}
        </div>
    )

}

export default Insights;