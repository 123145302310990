export const insights = "Insights"
export const userManagement = "User Management"

export const connection_header = {
    // label_searchByPhone: "Search by phone:",
    // textbox_searchByPhone: "Search by phone#",
    label_searchByEmail: "Search by Email:",
    textbox_searchByEmail: "Search by Email",
    label_searchByName: "Search by Name:",
    textbox_searchByName: "Search by Name",
    button_search: "Search",
    label_From: "From:",
    label_To: "To:",
    button_searchByDate: "Search by Date"
}

export const insights_screen = {
    sub_heading: "Search",
    label_company_name: "Company Name:",
    input_placeholder_name: "Search by Name",
    label_email: "Email:",
    input_placeholder_email: "Search by Email",
    label_country: "Country:",
    input_placeholder_country: "Search by Country",
    button_clear: "Clear",
    button_search: "Load",
    cardheader: "Insights",
    button_approve_all: "APPROVE ALL",
    button_reject_all: "REJECT ALL",
    navlink_1: "Awaiting Approval",
    navlink_2: "Approved",
    navlink_3: "Rejected",
    navlink_4: "Conflicts",

    table_col_name: "Company Name",
    table_col_type: "Type",
    table_col_country: "Country",
    table_col_incorporated: "Incorporated",
    table_col_email: "Email",
    table_col_request_date: "Request Date",
    table_col_actions: "Actions",

    button_view_detail: "VIEW DETAIL",
    button_approve: "APPROVE",
    button_reject: "REJECT",
    button_revert: "REVERT",
    table_no_request: "NO REQUEST AVAILABLE",
    modal_details_heading: "Company Details",
    modal_rejection_heading: "Reject Company Request",
    button_close: "Close"
}

export const userManagement_screen = {
    sub_heading: "User Board",
    button_add_new_user: "ADD NEW USER",
    heading_col_username: "Username",
    heading_col_role: "Role",
    heading_col_created_date: "Created Date",
    modal_addUser_heading_1: "Edit User",
    modal_addUser_heading_2: "Add New User",
    modal_form_label_fullName: "Full Name",
    modal_form_label_username: "Username",
    modal_form_input_help: "Only Alphabets, Numbers, Underscore and characters between 4 to 10.",
    // modal_form_label_phone: "Phone",
    modal_form_label_email: "Email",
    modal_form_label_role: "Role",
    modal_form_label_select_role: "Select Role",
    modal_form_label_permissions: "Permissions",
    modal_form_label_imageURL: "Image URL",
    modal_form_label_password: "Password",
    modal_form_alert_message: "Password Successfully Copied!",
    modal_form_label_less_password: "Make User Password Less",
    button_cancel: "Cancel",
    button_update: "Update",
    button_add: "Add",
    button_continue: "Continue",
    confirmationModal_deleteUser_message: "Are you sure you want to delete this user! Becuase this action cannot be undone."
}

export const changePassword_screen = {
    modal_form_label_current_password: "Current Password",
    modal_form_label_new_password: "New Password",
    modal_form_label_confirm_password: "Confirm Password",
    submit: "Submit",
    loading: "Loading...",

    sub_heading: "Single Certificate Issuance Form",
    phone_message1: "Phone Added",
    phone_message2: "Phone Connection Not Found, Add for Auto Issuance",
    textbox_placeholder: "9599XXXXXXXX",
    button_add: "Add",
    button_find: "Find",
    form_notification: "No connection selected. Please search for a connection first and then fill the form.",
    form_sub_heading: "Select Schema Type",
    form_label_phone: "Phone",
    form_label_connectionID: "Connection Id",
    
    modal_OK_heading: "Certificate Issuance Successful",
    modal_OK_message: "Certificate is successfully issued",
    modal_OK_button_continue: "Continue",
    modal_NOK_heading: "Certificate Issuance Failed",
    modal_NOK_button_continue: "Continue"
}

export const shared_info = {
    modal_contactus_heading: "Contact Us",
    // modal_contactus_phone_label: "Phone : ",
    // modal_contactus_phone_value: "+923362032476",
    modal_contactus_discord_label: "Discord : ",
    modal_contactus_discord_value: "https://discord.com/invite/ET6ysYqk",
    modal_contactus_email_label: "Email : ",
    modal_contactus_email_value: "admin@triendtravel.com",
    button_close: "Close",
    welcome_text: "Welcome!",
    logout_text: "Logout"
}