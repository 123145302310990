import Login from "views/auth/Login.js";
import Users from "views/Users";
import Insights from "views/Insights";
import ChangePassword from "views/ChangePassword";
import PERMISSIONS from "components/Permissions/Permissions";
import {
  insights, userManagement,
} from "content";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/insights",
    name: insights,
    icon: "fas fa-landmark text-orange",
    component: Insights,
    layout: "/admin",
    roles: ["admin"],
    permission: [PERMISSIONS.view_insights],
    includeInSidebar: true,
  },
  {
    path: "/users",
    name: userManagement,
    icon: "fa fa-users text-blue",
    component: Users,
    layout: "/admin",
    roles: ["admin"],
    permission: [PERMISSIONS.view_users],
    includeInSidebar: true,
  },
  {
    path: "/change-password",
    name: "Settings",
    icon: "ni ni-key-25 text-info",
    component: ChangePassword,
    roles: ["admin"],
    layout: "/admin",
    permission: [PERMISSIONS.change_password],
  },
];

export default routes;
