
/*eslint-disable*/
import React from "react";
import { ConfigContext } from "../../Context"

// reactstrap components
import { Container, Row } from "reactstrap";

const { version } = require('../../../package.json');

const Login = () => {

  const { config } = React.useContext(ConfigContext);

  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center text-center justify-content-center">
            <div className="copyright text-center text-xl-left text-muted text-white">
              <span className="mr-2">Powered by</span>
              <img
                style={{ height: 25 }}
                alt={config.authNav?.imgAlt}
                src={require("../../assets/img/brand/logo.png").default}
              />
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
