import axios from 'axios'
import { toast } from 'react-toastify';
import Auth from '../helpers/Auth.js';

class ChangePasswordServicesClass {

    constructor() {
        this.headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('itoken')
        };
    }

    changePassword = (currentPassword, newPassword) => {

        return new Promise((resolve, reject) => {
            try {
                var API_PATH = process.env.REACT_APP_API_URL + '/api/user/change-password'

                axios.post(API_PATH, {
                    currentPassword: currentPassword,
                    newPassword: newPassword
                },
                    {
                        headers: this.headers,
                    }
                ).then((data) => {
                    if (data.data.hasTokenExpired) {
                        Auth.signout();
                        window.location.reload();
                    }
                    else if (data.data.success) {
                        toast.success("Password changed successfully")
                        resolve(data.data.success)
                    } else if (data.data.error) {
                        toast.error(data.data.error)
                        resolve(false);
                    } else {
                        toast.error("Failed to change password")
                        resolve(false);
                    }
                }).catch((e) => {
                    console.log({ e })
                    toast.error(e.response.data.error);
                    resolve(false)
                });
            } catch (error) {
                reject(error)

                console.log(error)
            }
        })
    }
}

const ChangePasswordServices = new ChangePasswordServicesClass;

export default ChangePasswordServices;
