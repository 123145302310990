import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { changePassword_screen } from "../content";
// core components
import CredHeader from "../components/Headers/CredHeader.js";
import Auth from "helpers/Auth";
import ChangePasswordServices from 'services/ChangePassword';

const ChangePassword = () => {

  const branding = Auth.getBranding();

  const [showcurrentPassword, setShowcurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [formState, setFormState] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [disabledVal, setDisabledVal] = useState( "");

  const handleFormState = (identifier, data) => {
    setFormState((prevState) => {
      return {
        ...prevState,
        [identifier]: data
      }
    })
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (formState.newPassword !== formState.confirmPassword) {
      toast.error('New Password and Confirm Password do not match.');
      setIsLoading(false);
      return;
    }

    if (formState.newPassword.length < 8) {
      toast.error('Password must be at least 8 characters long');
      setIsLoading(false);
      return;
    }

    const success = await ChangePasswordServices.changePassword(formState.currentPassword, formState.newPassword);

    if (success) {
      Auth.signout();
    }

    setIsLoading(false);
  }
  return (
    <>
      <ToastContainer />

      <CredHeader heading={"Change Password"} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              {/* <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{changePassword_screen.sub_heading}</h3>
                  </Col>
                </Row>
              </CardHeader> */}
              <CardBody>
                <Form onSubmit={handleFormSubmit}>
                  <div className="row my-3">
                    <FormGroup className="mb-2 me-sm-2 mb-sm-0 col-md-6">
                      <fieldset disabled={disabledVal}>
                        {/* <Label
                          className="me-sm-2"
                          for={"input-password"}
                        >
                          {password}
                        </Label> */}


                        {/* <Input
                          id={"input-password" }
                          key={password}
                          name={data.identifier}
                          value={formState[data.identifier] ?? data?.default}
                          onChange={(e) => handleFormState(password, e.target.value)}
                          type={inputTypes[data.type]}
                          disabled={data.identifier == "User Id" ? true : isLoading}
                        /> */}

                      </fieldset>
                    </FormGroup>
                  </div>
                  <Row className="align-items-center my-2">
                    <Col xs="10" sm="11">
                      <FormGroup>
                        <label className="form-control-label required" htmlFor="input-username">
                          {changePassword_screen.modal_form_label_current_password}
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-current-password"
                          placeholder="********"
                          type={showcurrentPassword ? "text" : "password"}
                          autoComplete="off"
                          defaultValue={handleFormState['currentPassword']}
                          onKeyUp={(e) => { handleFormState('currentPassword', e.target.value); }}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="2" sm="1" className="d-flex align-items-center justify-content-between">
                      <button
                        onClick={() => {
                          setShowcurrentPassword(!showcurrentPassword);
                        }}
                        style={{ backgroundColor: branding.buttonColor ?? "#11cdef", color: branding.buttonTextColor ?? "#000" }}
                        type="button" className="widgets-icons-2 rounded-circle border-0 mr-1">
                        {showcurrentPassword ? <i class="fas fa-eye"></i> : <i class="fas fa-eye-slash"></i>}
                      </button>
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col xs="10" sm="11">
                      <FormGroup>
                        <label className="form-control-label required" htmlFor="input-username">
                          {changePassword_screen.modal_form_label_new_password}
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-new-password"
                          placeholder="********"
                          type={showNewPassword ? "text" : "password"}
                          autoComplete="off"
                          defaultValue={handleFormState['newPassword']}
                          onKeyUp={(e) => { handleFormState('newPassword', e.target.value); }}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="2" sm="1" className="d-flex align-items-center justify-content-between">
                      <button
                        onClick={() => {
                          setShowNewPassword(!showNewPassword);
                        }}
                        style={{ backgroundColor: branding.buttonColor ?? "#11cdef", color: branding.buttonTextColor ?? "#000" }}
                        type="button" className="widgets-icons-2 rounded-circle border-0 mr-1">
                        {showNewPassword ? <i class="fas fa-eye"></i> : <i class="fas fa-eye-slash"></i>}
                      </button>
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col xs="10" sm="11">
                      <FormGroup>
                        <label className="form-control-label required" htmlFor="input-username">
                          {changePassword_screen.modal_form_label_confirm_password}
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-confirm-password"
                          placeholder="********"
                          type={showConfirmPassword ? "text" : "password"}
                          autoComplete="off"
                          defaultValue={handleFormState['confirmPassword']}
                          onKeyUp={(e) => { handleFormState('confirmPassword', e.target.value); }}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="2" sm="1" className="d-flex align-items-center justify-content-between">
                      <button
                        onClick={() => {
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                        style={{ backgroundColor: branding.buttonColor ?? "#11cdef", color: branding.buttonTextColor ?? "#000" }}
                        type="button" className="widgets-icons-2 rounded-circle border-0 mr-1">
                        {showConfirmPassword ? <i class="fas fa-eye"></i> : <i class="fas fa-eye-slash"></i>}
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right" xs="11" sm="12">

                      <Button
                        color="primary"
                        disabled={isLoading}
                      // onClick={submit}
                      >
                        <span className="mr-1">{changePassword_screen.submit}</span>
                        {isLoading && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                      </Button>
                    </Col>
                  </Row>
                </Form>

              </CardBody>

            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ChangePassword;
