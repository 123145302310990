
import React, { useState } from "react";
import Auth from '../../helpers/Auth';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import axios from "axios";
const jwt = require("jsonwebtoken");

const Login = (props) => {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorVisibilityState, setErrorVisibilityState] = useState(false);
  const [loginError, setLoginError] = useState('Invalid username or password, Please try again!');

  const Authenticate = () => {
    setErrorVisibilityState(false);
    try {
      var details;
      details = {
        username: String(username).toLowerCase(),
        password: String(password),
      }

      var LOGIN_API_PATH = process.env.REACT_APP_API_URL + '/api/login'

      fetch(LOGIN_API_PATH, {
        method: 'POST',
        body: JSON.stringify(details),
        headers: { 'Content-Type': 'application/json' }
      }).then(res => res.json())
        .then(async json => {
          if (json['success']) {
            //TODO: verify token for more security
            let decodedToken = jwt.decode(json.token);
            let tokenRole = decodedToken['role'];

            const resp = await axios.get(`${process.env.REACT_APP_API_URL}/api/roles`, {
              headers: { 'Authorization': 'Bearer ' + json.token }
            })

            let roles = resp.data.roles?.map(e => e.role);


            if (roles.includes(tokenRole)) {

              localStorage.setItem('name', decodedToken['name']);
              localStorage.setItem('image', decodedToken['image']);

              localStorage.setItem('subject', decodedToken['subject']);
              localStorage.setItem('_id', decodedToken['_id'])
              localStorage.setItem('firstLoad', 'true');
              Auth.authenticate(json.token, json.exp, json.organization);

              // Remove Verification ID
              localStorage.removeItem('verificationId');

              window.location.reload();

            }
            else {
              setLoginError(json['error'])
              setErrorVisibilityState(true);
            }
          } else {
            //login unsuccessful error          
            setLoginError(json['error'])
            setErrorVisibilityState(true);
          }
        });
    } catch (error) {
      //print error
      console.log(error);
      setErrorVisibilityState(true);
    }
  }

  return (
    <>
      {/* Authentication */}
      <Col lg="6" md="9">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>
                Sign in with Credentials</small>
            </div>
            {errorVisibilityState && (
              <div className="text-center text-muted mb-4" >
                <small style={{ "color": "red" }} >{loginError}</small>
              </div>
            )}

            <Row className="justify-content-center align-items-center">
              <Col className="px-5">
                <Form role="form" onSubmit={(e) => { Authenticate(); e.preventDefault(); }}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-user-run" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Username"
                        type="text"
                        required={true}
                        value={username} onChange={(e) => setUsername(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        required={true}
                        value={password} onChange={(e) => setPassword(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit" >
                      Sign in
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;